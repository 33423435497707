<template>
  <div class="mb-10">
    <div>
      <div class="flex items-center justify-between">
        <h3 class="text-blue-800 font-semibold text-xl">Booking Options</h3>

        <div v-if="!edit">
          <Edit @edit="edit = true" />
        </div>
      </div>
    </div>

    <div class="mt-12">
      <div v-if="working" class="py-6 flex items-center justify-center">
        <loading-icon class="h-2 text-pink-500" />
      </div>
      <div v-else>
        <template v-if="!edit">
          <div v-if="!optionsWithPrices.length || optionsWithPrices.length <= optionLength"
            class="h-12 border border-dashed rounded-lg bg-gray-50 flex items-center justify-center -mt-2">
            <span class="text-sm text-gray-600">No booking options added.</span>
          </div>

          <div v-else class="space-y-8">
            <div v-for="(option, index) in optionsWithPrices" :key="index"
              class="bg-white flex-1 booking-option-shadow flex sm:flex-row flex-col rounded-xl">
              <div class="flex flex-1">
                <div class="flex-shrink-0 flex justify-end py-6 sm:py-10 pl-6">
                  <img :src="option.icon_url" class="h-8 w-8 lg:h-14 lg:w-14" alt="">
                </div>

                <div class="flex-1 py-6 sm:py-10 px-6">
                  <div class="flex items-center space-x-3">
                    <h3 class="font-bold text-xl text-blue-800">{{ option.name }}</h3>
                  </div>
                  <p class="text-sm text-gray-500 mt-2">
                    <!-- {{ optionDescription(option.slug) }} -->
                    {{ option.description }}
                  </p>
                </div>
              </div>

              <div
                class="w-full sm:w-40 flex-shrink-0 border-t sm:border-t-0 sm:border-l border-guestio-gray-50 flex items-center flex-row sm:flex-col justify-center py-4 sm:py-0">
                <span class="text-xl font-bold text-blue-800">
                  <template v-if="groupedPrices[option.id][0].price == 0">
                    Free
                  </template>
                  <template v-else>
                    ${{ groupedPrices[option.id][0].price == 'higher_price' ?
                      groupedPrices[option.id][0].overPrice + ' Requested' : groupedPrices[option.id][0].price }}
                  </template>
                </span>

                <span class="font-bold text-sm text-gray-500 mt-1">
                  <template v-if="['virtual-interview', 'live-platform'].includes(option.slug)">
                    /{{ groupedPrices[option.id][0].interview_duration }} min
                  </template>
                  <template v-else-if="['miniview', 'media-feature'].includes(option.slug)">
                    /question
                  </template>
                </span>

                <span v-if="groupedPrices[option.id].length > 1" class="ml-6 sm:ml-0 sm:mt-2 text-xs text-purple-50">
                  + {{ groupedPrices[option.id].length - 1 }} more
                </span>
              </div>
            </div>
          </div>
        </template>

        <template v-else>
          <ValidationObserver ref="bookingOptionsForm" v-slot="{ handleSubmit, invalid }" tag="div"
            class="max-w-xl w-full">
            <form method="post" @submit.prevent="handleSubmit(saveBookingOptions)">
              <div class="space-y-12">
                <template v-for="(option, index) in bookingOptions">
                  <component :is="`${option.slug}-option`" :option="option" :prices="groupedPrices[option.id]"
                    :key="`booking-option-${index}`" :selected-options="flattenBookingOptions"
                    @updated="updateSelectedOptions(option.id, $event)" />
                </template>
              </div>

              <div class="mt-8 flex items-center justify-end">
                <button type="button" class="font-bold text-pink-500" @click="edit = false">
                  Cancel
                </button>

                <button type="submit"
                  class="ml-8 h-12 rounded-full px-10 py-3 bg-indigo-gradiant text-white disabled:opacity-75 font-bold"
                  :disabled="invalid || saving">
                  <loading-icon v-if="saving" class="text-white h-2" />
                  <span v-else>Save</span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { chain } from 'lodash'
import Edit from "@/components/shared/Edit"
import VirtualInterviewOption from './booking-options/VirtualInterviewOption'
import MiniviewOption from './booking-options/MiniviewOption'
import LivePlatformOption from './booking-options/LivePlatformOption'
import MediaFeatureOption from './booking-options/MediaFeatureOption'
import VirtualEventOption from './booking-options/VirtualEventOption'

export default {
  name: 'GuestBookingOptions',

  props: {
    guest: Object,
  },

  components: {
    Edit,
    VirtualInterviewOption,
    MiniviewOption,
    LivePlatformOption,
    MediaFeatureOption,
    VirtualEventOption,
  },

  data() {
    return {
      working: true,
      edit: false,
      saving: false,
      bookingOptions: [],
      selectedOptions: {},
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },

    groupedPrices() {
      if (!this.guest.option_prices.length) {
        return {}
      }

      return chain(this.guest.option_prices).groupBy((price) => {
        return price.booking_option_id
      }).value()
    },

    flattenBookingOptions() {
      return chain(this.selectedOptions)
        .flatMap(options => options)
        .value()
    },

    bookingOptionIds() {
      return this.guest.option_prices.map(price => price.booking_option_id)
    },

    optionsWithPrices() {
      return this.bookingOptions.filter(option => this.bookingOptionIds.includes(option.id))
    },

    optionLength() {
      let length = 0;

      if (this.groupedPrices[8]) {
        length = 1;
      }

      return length;
    }
  },

  methods: {
    fetchBookingOptions() {
      api.get('/booking-options?filter[type]=guest')
        .then(({ data }) => {
          this.bookingOptions = data.data.filter(option => option.slug != 'pitch');
        })
        .finally(() => this.working = false)
    },

    refetchGuest() {
      this.$store.dispatch("auth/getUser").then(() => {
        this.$store.commit('guest/setGuest', this.user.guest)
      })
    },

    updateSelectedOptions(optionId, data) {
      this.$set(this.selectedOptions, optionId, data)
    },

    async saveBookingOptions() {

      let data = this.flattenBookingOptions.map(({ ...rest }) => rest);

      if (data.filter((data) => data.booking_option_id == 1 && data.price == 'higher_price').length > 0) {
        const formData = new FormData()

        this.saving = true;

        data.map((res, index) => {
          formData.append(`option[${index}]`, JSON.stringify(res));
          if (res.file) {
            formData.append(`file[]`, res.file);
          }
        })

        api.post(`/guests/${this.guest.id}/booking-options-request`, formData).then(() => {
          this.$toast.success('Data saved successfully! Team will contact you soon')
          this.edit = false
        }).catch((error) => {
          console.log(error);
        }).finally(() => {
          this.saving = false;
        })
      }
      else {

        let confirmed = await this.confirmLogin()

        if (!confirmed) {
          return
        }

        data.map((res) => {
          delete res.file
        })

        this.saving = true

        api.post(`/guests/${this.guest.id}/booking-options`, {
          options: this.flattenBookingOptions.map(({ ...rest }) => rest).map((res) => {
            delete res.file
            return res
          }),
        })
          .then(() => {
            this.$toast.success('Booking options saved')
            this.edit = false
            this.refetchGuest()
          })
          .catch(() => {
            this.$toast.error('Error! Something went wrong.')
          })
          .finally(() => {
            this.saving = false
          })
      }
    },

    optionDescription(slug) {
      switch (slug) {
        case 'miniview':
          return `Shows can submit up to ten questions for you to answer with a quick video/audio reply.`
        case 'media-feature':
          return `Shows can book you for a written interview on their blog/publication.`
        case 'live-platform':
          return `Shows can book you for their live streaming on Clubhouse, Facebook, Youtube or Instagram.`
        case 'virtual-event':
          return `Shows can book you for virtual stages like board meetings, virtual conferences or private dinners.`
        case 'virtual-interview':
        default:
          return `Shows can book you for an interview on their podcast, Youtube channel, etc.`
      }
    },
  },

  created() {
    this.fetchBookingOptions()
  }
}
</script>